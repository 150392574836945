module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kevin Pennarun","short_name":"Agado Dev","start_url":"/","background_color":"#4267b2","theme_color":"#4267b2","display":"minimal-ui","icon":"src/images/agado-logo/agado-logo_without_text.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
